<template>
  <div class="">
    <Home
      v-if="active == 'deliveryAssistantPortalHome'"
      ref="home"
      @checkMore="checkMore"
    ></Home>
    <DeliveryList
      v-else-if="active == 'deliveryAssistantPortalOrder'"
    ></DeliveryList>
    <van-tabbar
      :value="active"
      placeholder
      active-color="#B9921A"
      @change="onChange"
    >
      <van-tabbar-item name="deliveryAssistantPortalHome">
        <span>{{ $t("首页") }}</span>
        <template #icon="props">
          <img :src="props.active ? homeIconActive : homeIcon" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="deliveryAssistantPortalOrder" icon="setting-o">
        {{ $t("交付单") }}
        <template #icon="props">
          <img :src="props.active ? deliveryIconActive : deliveryIcon" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
import homeIcon from '@/images/home_icon.png'
import homeIconActive from '@/images/home_icon_active.png'
import deliveryIcon from './images/交付未点击@2x.png'
import deliveryIconActive from './images/交付点击@2x.png'
import Home from './home.vue'
import DeliveryList from './DeliveryList.vue'
export default {
  name: 'DeliveryAssistanPortl',
  components: {
    Home,
    DeliveryList,
  },
  data() {
    return {
      deliveryIcon,
      deliveryIconActive,
      homeIcon,
      homeIconActive,
      active: [
        'deliveryAssistantPortalHome',
        'deliveryAssistantPortalOrder',
      ].includes(this.$route.name)
        ? this.$route.name
        : 'deliveryAssistantPortalHome',
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('app/addKeepAlive', 'DeliveryAssistanPortl')
    })
  },
  beforeRouteLeave(to, from, next) {
    if (!['/deliver-manage', '/deliver-detail'].includes(to.path)) {
      this.$store.commit('app/deleteKeepAlive', 'DeliveryAssistanPortl')
    }
    next()
  },
  methods: {
    onChange(name) {
      const query = {
        replace: true,
      }
      // if (name === 'deliveryAssistantPortalOrder') {
      //   const { childCode = [],dealerIds = [], ...other } = this.$refs.home.filterParams
      //   Object.assign(query, { ...other, childCode: childCode.join(), dealerIds: dealerIds.join() })
      // }
      this.$router
        .push({
          name: `${name}`,
          // query,
        })
        .then(() => {
          this.$nextTick(() => {
            this.active = name
          })
        })
    },
    // 跳转交付单
    checkMore({ start, end }) {
      const query = {
        bookDeliveryTimeStart: start,
        bookDeliveryTimeEnd: end,
        deliveryStatuses: ['4000006', '4000007', '4000008', '4000010'],
      }
      const {
        childCode = [],
        dealerIds = [],
        ...other
      } = this.$refs.home.filterParams
      Object.assign(query, {
        ...other,
        childCode: childCode.join(),
        dealerIds: dealerIds.join(),
      })
      this.$router.push({
        path: '/deliver-manage',
        query,
      })
    },
  },
}
</script>
<style lang="less" scoped>
/deep/.van-tabbar--fixed {
  max-width: 500px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
